/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@font-face {
  font-family: 'Diodrum-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Regular'),
    url('fonts/Diodrum-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Semibold';
  font-style: normal;
  font-weight: 600;
  src: local('Diodrum-Semibold'),
    url('fonts/Diodrum-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Medium'),
    url('fonts/Diodrum-Medium.otf') format('opentype');
}

:root {
  /* --main-bg: #fafafb; */
  --main-bg: #F5F5F5;
  --txt-color: #455560;
  --second-bg: #ffffff;
  --sidebar-bg: #E1E1E1;
  --topnav-height: 55px;
  --tabNavigatorBar-height: 40px;
  --sidebar-width: 300px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}



body {
  /* font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */

  font-family: 'Diodrum-Regular';
  letter-spacing: 0.7px;
  background-color: var(--main-bg);
  color: var(--txt-color);
  overflow: hidden;
  color: #313131 !important;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}

ul {
  list-style-type: none;
}

h2.page-header {
  font-family: 'Diodrum-Semibold';
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0px 15px 0px;
  display: block;
  width: 100%;
  /* text-transform: capitalize; */
  color: #313131 !important;
}

h3 {
  font-family: 'Diodrum-Semibold';
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  position: relative;
  margin: 5px;
}

::-webkit-scrollbar-thumb {
  background: #349eff;
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.chat-dashboard {
  flex-grow: 1;
  display: flex;
}

.chat-window {

  padding: 0px 10px 0px 15px;
  display: flex;
  width: 0px;
  flex-grow: 1;
  flex-direction: column;



}

.chatgpt .message {
  background-color: var(--second-bg);
  /* var(--main-bg); */
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.chatgpt .question {
  margin-right: 20px;
  min-width: 30%;
  max-width: 80%;
  line-height: 1.5;
  margin-bottom: 7px;
  /* text-align: justify;
    text-justify: inter-word; */
}

.chatgpt .answer {
  /* var(--main-bg); */
  margin-top: 0px;
  margin-bottom: 0px;
  /* width: 95% !important; */
  /* text-align: justify;
    text-justify: inter-word; */
  line-height: 1.5;
  /* text-align: justify; */
  color: #313131 !important;
  font-size: 16px;
}

.imageChatBody {
  flex-grow: 1;
  width: 100%;
  display: flex;
  height: 0px;
  overflow-y: scroll;
  padding: 28px 25px 8px 40px;

}

.emptySpaceBelowPromptForm {
  flex-grow: 1;
}

.CardList {
  display: block;
}

.formSection {

  display: block;
  width: 100%;
  min-height: 115px;
}

.sidebar>.scrollview::-webkit-scrollbar {
  display: none;
}

.sidebar>.scrollview:hover::-webkit-scrollbar {
  display: block;
}

.scrollview {
  /* flex-grow: 1; */
  width: 100%;
  /* display: flex; */
  /* height: 70vh; */
  overflow: auto;
  scroll-behavior: smooth !important;
}

.scrollview::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-radius: 5px 5px 0 0;
  height: 16px;
  width: 16px;
  background-position: center 4px;

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.scrollview::-webkit-scrollbar-button:single-button:vertical:increment {
  border-radius: 0px 0px 5px 5px;
  height: 16px;
  width: 16px;
  background-position: center 4px;

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-radius: 5px 0px 0 5px;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-radius: 0 5px 5px 0;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.defaultMessage {
  list-style-type: disc !important;
  list-style-type: inside;
  margin-top: 15px;
  margin-left: 35px;
}

.defaultMessage li {
  margin-bottom: 15px
}

@import url("./styles/header.css");
@import url("./styles/layout.css");
@import url("./styles/loading.css");
@import url("./styles/theme.css");
@import url("./styles/sidebar.css");
@import url("./styles/antd.css");
@import url("./styles/chatGPT.css");
@import url("./styles/promptComposer.css");