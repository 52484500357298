.promptComposerForm {
    margin-top: 8px;
    max-width: 1000px;
    font-family: Diodrum-Regular;
}
.promptComposerItem {
    max-width: 1000px;
    width: 100%;

    display: inline-block;
    margin-bottom: 8px;
    margin-left: 8px;
}
.promptComposerItemField {
    font-family: Diodrum-Regular;
    width: 100%;
    max-width: 236px;
}
.promptComposerItemLabel {
    display: inline-block;
    vertical-align: top;
    width: 130px;
    text-align: right;
    line-height: 32px;
    margin-right: 8px;
  }
.promptComposerPromptPreview{
    font-family: Diodrum-Regular;
    margin-bottom: 16px;
    max-height: 16em;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
}
.promptComposerIntro{
    font-family: Diodrum-Regular;
}